<template>
  <div>
    <!-- 头部 -->
    <mHeader :dlogBr="true" val="yes"></mHeader>
    <!-- 进度条 -->
    <Progress :val="3" type="mb"></Progress>

    <!-- 内容 -->
    <div class="neiron">
      <!-- 选择支付方式 -->
      <div class="left-payment">
        <div class="psg-payment-way-m">
          <el-radio-group v-model="paymentWay">
            <div class="psg-payment-way-m-it">
              <el-radio :label="3">Credit Card</el-radio>
              <div class="zffsImg-box">
                <div class="zffsImg1">
                  <img src="@/assets/images/visa.png" alt="" />
                </div>
                <div class="zffsImg2">
                  <img src="@/assets/images/mastercard.png" alt="" />
                </div>
                <div class="zffsImg3">
                  <img src="@/assets/images/uniquely.png" alt="" />
                </div>
                <div class="zffsImg4">
                  <img src="@/assets/images/american.png" alt="" />
                </div>
                <div class="zffsImg5">
                  <img src="@/assets/images/dineers.png" alt="" />
                </div>
              </div>
            </div>
            <div class="psg-payment-way-m-it">
              <el-radio :label="6">Wechat Pay</el-radio>
              <div class="zffsImg-box">
                <div class="zffsImg-wz">
                  <img src="@/assets/images/WechatPayLogo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="psg-payment-way-m-it">
              <el-radio :label="5">Alipay</el-radio>
              <div class="zffsImg-box">
                <div class="zffsImg-wz">
                  <img src="@/assets/images/AlipayLogo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div v-if="showApplePay" class="psg-payment-way-m-fgx"></div>
            <div v-if="showApplePay" class="psg-payment-way-m-it">
              <!-- <div class="psg-payment-way-m-fgx"></div>
              <div class="psg-payment-way-m-it"> -->
              <el-radio :label="4">Apple Pay</el-radio>
              <div class="zffsImg-box">
                <!-- <div class="zffsImg6">
                    <SvgIcon
                      iconClass="pay_pay_logo"
                      style="font-size: 45px"
                    ></SvgIcon>
                  </div> -->
              </div>
            </div>
            <!-- <div class="psg-payment-way-m-fgx"></div> -->
            <!-- <div class="psg-payment-way-m-it">
              <el-radio :label="1">PayPay</el-radio>
              <div class="zffsImg-box">
                <div class="zffsImg6">
                  <SvgIcon
                    iconClass="pay_pay_logo"
                    style="font-size: 12vw"
                  ></SvgIcon>
                </div>
              </div>
            </div> -->
          </el-radio-group>
        </div>
      </div>
      <!-- <div class="left-payment no-left-payment" v-else>
          <div class="tit"><SvgIcon style="margin-right:4px" iconClass="home_details"></SvgIcon>Notice</div>
          Apple Pay is currently the only payment method available on the English Web Ticket Store. <br>
          To checkout with Apple Pay, please use devices compatible with Apple Pay: iPhone, iPad, and Mac.
        </div> -->
    </div>

    <!-- 底部按钮 -->
    <div class="btm-btn">
      <!--  -->
      <div class="price">
        <p>Total</p>
        <span>￥{{ setSumPrice(param.totalPrice, 1) || "-" }}</span>
      </div>
      <!-- 抽屉 按钮 -->
      <div @click="drawerBuer = true" class="drawerBtn">
        Booking Details <i class="el-icon-arrow-up"></i>
      </div>
      <!-- 按钮 -->
      <apple-pay-button
        v-if="showApplePay && paymentWay == 4"
        @click="onApplePayButtonClickedJapan"
        buttonstyle="black"
        type="plain"
        locale="zh-CN"
      ></apple-pay-button>
      <!-- <el-button v-else @click="continueBtn">Continue</el-button> -->
      <el-button v-else class="totalBtn-btn" @click="continueBtn"
        >Continue</el-button
      >
    </div>

    <!-- 抽屉 -->
    <div class="drawer-box">
      <el-drawer
        :visible.sync="drawerBuer"
        direction="btt"
        :before-close="handleClose"
      >
        <div class="right">
          <div
            v-if="param.adult || param.child || param.student"
            class="right-tit"
          >
            <p>Moominvalley Park 1-Day Pass</p>
            <span
              >{{ translateMonth(param.date.split("-")[1]) }}
              {{ param.date.split("-")[2] }},
              {{ param.date.split("-")[0] }}</span
            >
          </div>
          <div v-if="param.adult" class="right-adult">
            <div class="item">
              <p>Quantity</p>
              <span>{{ param.adult ? param.adult + "x" : "-" }} Adult</span>
            </div>
            <div class="item">
              <p>Subtotal</p>
              <span
                >￥{{ setSumPrice(param.adultPrice, param.adult) || "-" }}</span
              >
            </div>
          </div>
          <div v-if="param.child" class="right-child">
            <div class="item">
              <p>Quantity</p>
              <span>{{ param.child ? param.child + "x" : "-" }} Child</span>
            </div>
            <div class="item">
              <p>Subtotal</p>
              <span
                >￥{{ setSumPrice(param.childPrice, param.child) || "-" }}</span
              >
            </div>
          </div>
          <div v-if="param.student" class="right-child">
            <div class="item">
              <p>Quantity</p>
              <span
                >{{ param.student ? param.student + "x" : "-" }} Secondary
                Student</span
              >
            </div>
            <div class="item">
              <p>Subtotal</p>
              <span
                >￥{{
                  setSumPrice(param.studentPrice, param.student) || "-"
                }}</span
              >
            </div>
          </div>
          <div
            v-if="param.nightAdult || param.nightChild || param.nightStudent"
            class="right-tit"
            style="padding-top: 20px"
          >
            <p>Moominvalley Park Night Pass</p>
            <span
              >{{ translateMonth(param.date.split("-")[1]) }}
              {{ param.date.split("-")[2] }},
              {{ param.date.split("-")[0] }}</span
            >
          </div>

          <div v-if="param.nightAdult" class="right-adult">
            <div class="item">
              <p>Quantity</p>
              <span
                >{{
                  param.nightAdult ? param.nightAdult + "x" : "-"
                }}
                Adult</span
              >
            </div>
            <div class="item">
              <p>Subtotal</p>
              <span
                >￥{{
                  setSumPrice(param.nightAdultPrice, param.nightAdult) || "-"
                }}</span
              >
            </div>
          </div>
          <div v-if="param.nightChild" class="right-child">
            <div class="item">
              <p>Quantity</p>
              <span
                >{{
                  param.nightChild ? param.nightChild + "x" : "-"
                }}
                Child</span
              >
            </div>
            <div class="item">
              <p>Subtotal</p>
              <span
                >￥{{
                  setSumPrice(param.nightChildPrice, param.nightChild) || "-"
                }}</span
              >
            </div>
          </div>
          <div v-if="param.nightStudent" class="right-child">
            <div class="item">
              <p>Quantity</p>
              <span
                >{{
                  param.nightStudent ? param.nightStudent + "x" : "-"
                }}
                Secondary Student</span
              >
            </div>
            <div class="item">
              <p>Subtotal</p>
              <span
                >￥{{
                  setSumPrice(param.nightStudentPrice, param.nightStudent) ||
                  "-"
                }}</span
              >
            </div>
          </div>

          <div class="right-user">
            <div class="item">
              <p>Contact</p>
              <span>{{ param.contact }}</span>
            </div>
            <div class="item">
              <p>Email</p>
              <span>{{ param.gmail }}</span>
            </div>
          </div>
          <!-- 总价 -->
          <div class="right-total">
            <div class="right-total-jg">
              <p>Total</p>
              <span>￥{{ setSumPrice(param.totalPrice, 1) || "-" }}</span>
            </div>

            <div @click="drawerBuer = false" class="drawerBtn">
              Booking Details <i class="el-icon-arrow-down"></i>
            </div>
          </div>

          <!-- 按钮 -->
          <apple-pay-button
            v-if="showApplePay && paymentWay == 4"
            @click="onApplePayButtonClickedJapan"
            buttonstyle="black"
            type="plain"
            locale="zh-CN"
          ></apple-pay-button>
          <!-- <el-button v-else @click="continueBtn">Continue</el-button> -->

          <el-button @click="continueBtn" v-else class="totalBtn-btn"
            >Continue</el-button
          >
        </div>
      </el-drawer>
    </div>

    <!-- 支付二维码弹窗 -->

    <el-dialog
      title="Please scan to pay."
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="zhifuHandleClose"
    >
      <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import mHeader from "@/components/m_header.vue";
import Progress from "@/components/progress.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import {
  post_orderTopay,
  post_orderPayresult,
  get_orderQueryOrder,
} from "@/api/common.js";
export default {
  data() {
    return {
      showApplePay: false, // 苹果支付
      id: "",
      param: JSON.parse(localStorage.getItem("orderParam")) || {}, // 订单参数
      drawerBuer: false, // 抽屉弹窗
      paymentWay: 3, // 支付方式单选框
      dialogVisible: false,
      zhifuTimer: null,
    };
  },
  beforeDestroy() {
    console.log("页面销毁清除定时器");
    clearInterval(this.zhifuTimer);
    this.zhifuTimer = null;
  },
  methods: {
    zhifuHandleClose(done) {
      console.log("关闭弹窗");
      clearInterval(this.zhifuTimer);
      done();
      document.getElementById("qrcode").innerHTML = "";
    },
    creatQrCode(val) {
      console.log(val);
      // return
      window.location.href = val;
      // return
      // this.$nextTick(() => {
      //   if (this.$refs.qrCodeUrl) {
      //     var qrcode = new QRCode(this.$refs.qrCodeUrl, {
      //       text: val, // 需要转换为二维码的内容
      //       width: 100,
      //       height: 100,
      //       colorDark: "#000000",
      //       colorLight: "#ffffff",
      //       correctLevel: QRCode.CorrectLevel.H,
      //     });
      //     console.log("二维码", qrcode);
      //   }
      // });
    },
    async chaxunFn() {
      let res = await get_orderQueryOrder({ id: this.id });
      console.log(res.data.data.status);
      return res.data.data;
    },
    // 处理苹果支付数据格式
    setApplePayOrderDesc() {
      // "displayItems":  [
      //     {
      //         "label": "Tax",
      //         "amount": {
      //             "value": "2.50",
      //             "currency": "USD"
      //         }
      //     },
      //     {
      //         "label": "Moominvalley Park 1-Day Pass",
      //         "amount": {
      //             "value": "5.00",
      //             "currency": "USD"
      //         }
      //     }
      // ],
      let arr = this.param;
      let newArr = [];
      if (arr.adult) {
        newArr.push({
          label: "Moominvalley Park 1-Day Pass-Adult",
          amount: {
            value: +arr.adultPrice.toString(),
            currency: "JPY",
          },
        });
      }
      if (arr.child) {
        newArr.push({
          label: "Moominvalley Park 1-Day Pass-Child",
          amount: {
            value: +arr.childPrice.toString(),
            currency: "JPY",
          },
        });
      }

      console.log("商品数据格式", newArr);
      return newArr;
    },
    handleClose(done) {
      done();
    },
    // 苹果支付按钮
    async onApplePayButtonClickedJapan() {
      let strTotal = this.param.totalPrice;
      strTotal = strTotal.toString();
      console.log("总价", strTotal);
      console.log(window.PaymentRequest);
      console.log("id", this.id);
      console.log("支付类型", this.paymentWay);
      // let strTotal = this.param.totalPrice;
      // console.log(strTotal);
      if (!PaymentRequest) {
        return;
      }
      const paymentMethodData = [
        {
          supportedMethods: "https://apple.com/apple-pay", // 如果以后有更改 去https://applepaydemo.apple.com/ 下面的show source查看更改 一般不需要改
          data: {
            version: 3,
            merchantIdentifier: "merchant.com.moominvalleypark-booking", // 在 AppStore开发者里面看,一般不用换
            merchantCapabilities: ["supports3DS"],
            supportedNetworks: [
              //支持卡类型
              "amex",
              "discover",
              "masterCard",
              "visa",
            ],
            countryCode: "JP", //国家代号
          },
        },
      ];
      // 订单详情
      const paymentDetails = {
        total: {
          //总价格
          label: "JTRWeb LTD.", //店铺名字
          amount: {
            value: strTotal, //价格
            currency: "JPY", //货币
          },
        },
        displayItems: this.setApplePayOrderDesc(),
        modifiers: [
          {
            supportedMethods: "https://apple.com/apple-pay", // 如果以后有更改 去https://applepaydemo.apple.com/ 下面的show source查看更改 一般不需要改
            data: {
              paymentMethodType: "debit",
            },
            total: {
              label: "JTRWeb LTD.",
              amount: {
                value: strTotal, //价格
                currency: "JPY",
              },
            },
          },
        ],
      };
      //是否需要在Apple Pay填写资料
      const paymentOptions = {
        requestPayerName: false,
        requestBillingAddress: false,
        requestPayerEmail: false, //是否需要填写邮箱
        requestPayerPhone: false, //是否需要填写电话
        requestShipping: false,
        shippingType: "shipping",
      };

      // Create PaymentRequest
      const request = new PaymentRequest(
        paymentMethodData,
        paymentDetails,
        paymentOptions
      );
      request.onmerchantvalidation = async (event) => {
        // 请求后端,获取新的merchant session.
        const merchantSessionPromise = await post_orderTopay({
          id: this.id,
          pay_type: this.paymentWay,
        });
        console.log("merchantSessionPromise", merchantSessionPromise);

        event.complete(merchantSessionPromise.data.data.content);
      };

      request.onpaymentmethodchange = (event) => {
        // Define PaymentDetailsUpdate based on the selected payment method.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total,
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingoptionchange = (event) => {
        // Define PaymentDetailsUpdate based on the selected shipping option.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total,
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingaddresschange = (event) => {
        // Define PaymentDetailsUpdate based on a shipping address change.
        const paymentDetailsUpdate = {
          total: {
            label: "JTRWeb LTD.",
            amount: {
              value: strTotal,
              currency: "JPY",
            },
          },
        };
        event.updateWith(paymentDetailsUpdate);
      };

      let response = await request.show();
      this.paymentData = JSON.stringify(response.details.token.paymentData);

      const status = "success";
      await response.complete(status);

      console.log(response);
      let resPay = await post_orderPayresult(this.id, response);

      if (resPay.data.status == 302) {
        this.$router.push(resPay.data.data);
      }
      console.log("resPay", resPay);
    },
    // 下一步按钮
    async continueBtn() {
      const loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      try {
        let res = await post_orderTopay({
          id: this.id,
          pay_type: this.paymentWay,
          terminal: 1,
        });
        console.log(res);
        sessionStorage.removeItem("moominOrderId");
        loading.close();
        // 支付接口发起成功后
        this.param.gmail = "";
        this.param.contact = "";
        localStorage.setItem("orderParam", JSON.stringify(this.param));
        // ------------------
        if (this.paymentWay == 1) {
          // console.log("1");
          window.localStorage.removeItem("callbackHTML");

          window.localStorage.setItem("callbackHTML", res.data.data.content);

          // 读取本地保存的html数据，使用新窗口打开
          var newWin = window.open("", "_self");

          newWin.document.write(localStorage.getItem("callbackHTML"));

          // 关闭输出流

          newWin.document.close();
        } else if (this.paymentWay == 3) {
          let options = {
            language: res.data.data.content.lang,
          };
          pop.pay(res.data.data.content.payment_key, options);
          // console.log(pop);
        } else if (this.paymentWay == 6 || this.paymentWay == 5) {
          // window.location = res.data.data.content;
          // window.open(res.data.data.content, '_self')
          // this.dialogVisible = true;

          this.creatQrCode(res.data.data.content, 1);

          this.zhifuTimer = setInterval(async () => {
            let newData = await this.chaxunFn();
            console.log("支付状态", newData);
            if (newData.status != 1) {
              clearInterval(this.zhifuTimer);
              this.$router.push(`/m_order_preview?x=${newData.sign}`);
            }
          }, 1000);
        }

        // this.$router.push({
        //   path: "/m_order_preview",
        // });
      } catch (error) {
        console.log("error", error);
        loading.close();
        this.$router.push({
          path: "/m_info",
        });
        this.$message.warning("Fail to continue");
      }
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != "/m_payment") {
        this.$router.replace(`/m_payment?id=${this.id}`);
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != "/payment") {
        this.$router.replace(`/payment?id=${this.id}`);
      }
    }

    window.ApplePaySession === undefined
      ? (this.showApplePay = false)
      : (this.showApplePay = true);
  },
  created() {
    this.id = sessionStorage.getItem("moominOrderId");
    console.log(this.id);

    if (
      !JSON.parse(localStorage.getItem("orderParam")) ||
      JSON.parse(localStorage.getItem("orderParam")).gmail == ""
    ) {
      this.$router.push({
        path: "/m_info",
      });
    }
  },
  components: {
    mHeader,
    Progress,
    SvgIcon,
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  width: 80%;
  .el-dialog__body {
    padding: 20px;
    img {
      width: 60%;
      height: 60%;
    }
  }
}
.qrcode {
  display: flex;
  justify-content: center;
}
.neiron {
  padding: 0 16px;
  .left-payment {
    background-color: #fff;
    border-radius: 12px;
    padding: 5.3333vw;
    margin-top: 16px;
    .psg-payment-way-m {
      width: 100%;
      /deep/.el-radio-group {
        width: 100%;
      }
    }
    .psg-payment-way-m-fgx {
      height: 1px;
      background-color: #f0f0f0;
      margin: 24px 0;
    }
    .psg-payment-way-m-it {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      /deep/.el-radio {
        display: flex;
        align-items: center;
        .el-radio__label {
          font-size: 4.2667vw;
          color: #12334c;
        }
        .el-radio__inner {
          width: 3.7333vw;
          height: 3.7333vw;
          &::after {
            width: 1.0667vw;
            height: 1.0667vw;
          }
        }
      }
      .zffsImg-box {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        .zffsImg1 {
          // margin-right: 2.4vw;
          align-items: center;
          justify-content: center;
          width: 9.0667vw;
          height: 5.3333vw;
          display: inline-block;
          background-color: #f6f6f6;
          display: flex;
          border-radius: 4px;
          img {
            width: 100%;
            // height: 100%;
          }
        }
        .zffsImg2 {
          // margin-right: 2.4vw;
          align-items: center;
          justify-content: center;
          width: 9.0667vw;
          height: 5.3333vw;
          display: inline-block;
          // background-color: #151514;
          display: flex;
          border-radius: 4px;
          img {
            width: 100%;
            // height: 120%;
          }
        }
        .zffsImg3 {
          // margin-right: 2.4vw;
          align-items: center;
          justify-content: center;
          width: 9.0667vw;
          height: 5.3333vw;
          display: inline-block;
          // background-color: #151514;
          display: flex;
          img {
            width: 100%;
            // height: 100%;
          }
        }
        .zffsImg4 {
          // margin-right: 2.4vw;
          align-items: center;
          justify-content: center;
          width: 9.0667vw;
          height: 5.3333vw;
          display: inline-block;
          // background-color: #151514;
          display: flex;
          img {
            width: 100%;
            // height: 100%;
          }
        }
        .zffsImg5 {
          // margin-right: 2.4vw;
          align-items: center;
          justify-content: center;
          width: 9.0667vw;
          height: 5.3333vw;
          display: inline-block;
          // background-color: #151514;
          display: flex;
          img {
            width: 100%;
            // height: 100%;
          }
        }

        .zffsImg6 {
          margin-right: 2.4vw;
          align-items: center;
          justify-content: center;
          width: 9.0667vw;
          height: 5.8667vw;
          display: inline-block;
          background-color: #f6f6f6;
          display: flex;
          border-radius: 4px;
        }

        .zffsImg-wz {
          width: 6.4vw;
          height: 6.4vw;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    /deep/.el-radio__input.is-checked + .el-radio__label {
      color: #12334c;
    }
    /deep/.el-radio__input.is-checked .el-radio__inner {
      border-color: #12334c;
      background: #12334c;
    }
    /deep/.el-radio {
      font-weight: bold;
    }
  }
  .no-left-payment {
    border: 2px solid #d94e4d;
    padding: 20px;
    background-color: #fef8f8;
    font-size: 14px;
    .tit {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}

//
.btm-btn {
  width: 100%;
  background-color: #fff;
  margin-top: 16px;
  padding: 18px 16px 16px;
  position: fixed;
  bottom: 0;
  .price {
    display: flex;
    justify-content: space-between;
    font-size: 5.3333vw;
    color: #12334c;
    p {
      font-weight: 700;
    }
    span {
      font-weight: 400;
    }
  }

  .el-button {
    padding: 3.2vw 0;
    width: 100%;
    background-color: #12334c;
    color: #fff;
    border-radius: 7px;
    font-size: 4.2667vw;
  }
}

.drawer-box {
  /deep/.el-drawer {
    height: unset !important;
  }
  /deep/.el-drawer__header {
    padding: 16px 16px 0;
    margin-bottom: 0;

    .el-drawer__close-btn {
      width: 6.4vw;
      border-radius: 50%;
      background-color: #f0f0f0;
      height: 6.4vw;
      font-size: 3.7333vw;
    }
  }

  /deep/.el-drawer__body {
    padding: 16px;

    .right {
      background-color: #fff;

      .right-tit {
        border-bottom: 1px solid #b2bac13d;
        padding-bottom: 16px;
        font-size: 4.8vw;
        color: #12334c;
        font-weight: bold;
        p {
          margin-bottom: 5px;
        }
        span {
          font-size: 4.2667vw;
        }
      }
      .right-adult,
      .right-child,
      .right-user {
        // height: 21.8667vw;
        padding: 4.2667vw 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 4.2667vw;
        border-bottom: 1px solid #b2bac13d;
        .item:first-child {
          margin-bottom: 3.2vw;
        }
        .item {
          display: flex;
          justify-content: space-between;
          color: #12334c;

          p {
            font-weight: 400;
          }
          span {
            font-weight: bold;
            width: 50%;
            text-align: right;
            word-wrap: break-word;
          }
        }
      }
      .right-total {
        padding-top: 16px;
        font-weight: bold;
        .right-total-jg {
          display: flex;
          justify-content: space-between;
          font-size: 5.3333vw;
          color: #12334c;
        }
      }

      .el-button {
        width: 100%;
        background-color: #12334c;
        color: #fff;
        padding: 3.2vw 0;
        border-radius: 7px;
        font-size: 4.2667vw;
      }
    }
  }
}

.drawerBtn {
  // width: 28vw;
  display: inline-block;
  height: 5.8667vw;
  border-radius: 1.3333vw;
  background: #f3f4f5;
  font-size: 3.2vw;
  color: #12334c;
  line-height: 5.8667vw;
  text-align: left;
  padding-left: 0.5333vw;
  padding-right: 0.5333vw;
  font-weight: 500;
  margin: 1.0667vw 0 4.2667vw;
}

apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 10.6667vw;
  --apple-pay-button-border-radius: 7px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
  // margin-left: 15px;
  display: block;
}
</style>
